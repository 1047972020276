<template>
  <el-cascader
    style="width:400px"
    :options="deptTree"
    disabled
    :show-all-levels="false"
    :props="cascProps"
    filterable
    placeholder="无"
    v-model="department"
    size="small"
  ></el-cascader>
</template>

<script>
import { getMenusTree } from './api'
export default {
  name: 'SelectDept',

  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },

  computed: {
    department: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      deptTree: [],
      cascProps: {
        label: 'name',
        value: 'id',
        checkStrictly: true,
        expandTrigger: 'hover',
        emitPath: false,
        multiple: false,
        leaf: 'leaf'
      }
    }
  },

  created() {
    this.getMenusTree()
  },

  methods: {
    async getMenusTree() {
      let res = await getMenusTree()
      this.deptTree = this.setLeaf(res)
    },

    setLeaf(list) {
      return list.map(item => {
        if (item.children && item.children.length > 0) item.children = this.setLeaf(item.children)
        else {
          item.leaf = true
          delete item.children
        }
        return item
      })
    }
  }
}
</script>

<style></style>
